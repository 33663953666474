import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { HtmlContent, Image } from '../components/common';
import { Layout } from '../components';
import './about.scss';

class About extends Component {
  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      aboutTitle,
      biography,
      personalPortrait,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <div className="cols">
          <div className="col col-left">
            <div className="image-wrapper">
              <Image image={personalPortrait} />
            </div>
          </div>
          <div className="col col-right">
            <h1 className="title">{aboutTitle.text}</h1>
            <HtmlContent className="biography" content={biography.html} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        projectSlug,
      }
    },
    page: prismicAbout {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        aboutTitle: about_title {
          text
        }
        biography {
          html
        }
        personalPortrait: personal_portrait {
          fluid(maxWidth: 1000) {
            ...GatsbyPrismicImageFluid
          }
          url
          alt
        }
      }
    }
  }
`;
